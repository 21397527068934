

function PopupHelp(helpType) {
    let FieldHelp = window.open("/help/main.aspx?HelpType=" + helpType, "Help", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=no,width=600,height=300");
    FieldHelp.focus();
}

function PopupHelpEmployee(helpType) {
    let FieldHelp = window.open("/employee/help/main.aspx?HelpType=" + helpType, "Help", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=no,width=600,height=300");
    FieldHelp.focus();
}

function PopupHelpAgent(helpType) {
    let FieldHelp = window.open("/agent/help/main.aspx?HelpType=" + helpType, "Help", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=no,width=600,height=300");
    FieldHelp.focus();
}

function OpenPolicy() {
    let Policy = window.open("https://www.fragomen.com/fragomen/privacy-policies", "Policy", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=no,width=700,height=700");
    Policy.focus();
}

function OpenEVerifyParticipationNotice() {
    let EVerifyNotice = window.open("/EVerifyParticipationNotice.aspx?", "Policy", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,width=675,height=475");
    EVerifyNotice.focus();
}

function PopupUserGuide() {
    let UserGuide = window.open("/UserGuide/index.aspx?", "UserGuide", "toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=no,resizable=yes,width=900,height=800");
    UserGuide.focus();
}

export { PopupHelp, PopupHelpEmployee, PopupHelpAgent, OpenPolicy, OpenEVerifyParticipationNotice, PopupUserGuide }