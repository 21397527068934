

var masterStorage;
try { //test the browser supports localStorage (IE often has problems)
    var x = 'test_masterStorage_available_' + Date.now();
    localStorage.setItem(x, x);
    var y = localStorage.getItem(x);
    localStorage.removeItem(x);
    if (x !== y) { throw new Error(); }
    masterStorage = localStorage;
}
catch (e) {
    masterStorage = new MemoryStorage('masterStorage');
}



/*note: since this script initializes UI elements, locatting it in the <head> prevents flickering*/
$(function () {

    if (document.getElementById("sidebar-wrapper")) {
        if (masterStorage.getItem('sidebar-wrapper') == 'hidden') {
            $("#sidebar-wrapper").removeClass("in");
            $("#sidebar-wrapper").removeClass("show");
            $("#wrapper").removeClass("toggled");
            $("#menu-toggle").addClass("collapsed");
            $("#menu-toggle").attr("aria-expanded", false);
        } else {
            $("#sidebar-wrapper").addClass("show");
            $("#wrapper").addClass("toggled");
        }
    }
 
    $("#menu-toggle").on("click", function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });

    $('.navbar-toggle-always').on('click.bs.collapse', function (e) {
        var $toggleLabel = $(this).find('.navbar-toggle-label')

        if ($(this).hasClass('collapsed')) {
            $(this).data('label-collapsed', $toggleLabel.text())
            $toggleLabel.text($(this).data('label-expanded'))
            masterStorage.setItem('sidebar-wrapper', 'shown');
        } else {
            $toggleLabel.text($(this).data('label-collapsed'))
            masterStorage.setItem('sidebar-wrapper', 'hidden');
        }
    })
  
    //* end fancy toggle menu sidebar*//

    //this  maintains state of any bootstrap collapse panels with savecollapsestate class across pages
    $.each($('.savecollapsestate'), function () {
        if (masterStorage.getItem($(this).attr('id')) == 'hidden') {
            $(this).removeClass("in");
            $("a[aria-controls=" + $(this).attr('id') + "]").attr("aria-expanded", false);
        } else if (masterStorage.getItem($(this).attr('id')) == 'shown') {
            $(this).addClass("in");
            $("a[aria-controls=" + $(this).attr('id') + "]").attr("aria-expanded", true);
        }
    });

    $(".savecollapsestate").on('hidden.bs.collapse', function () {
        masterStorage.setItem($(this).attr('id'), 'hidden');
    });

    $(".savecollapsestate").on('shown.bs.collapse', function () {
        masterStorage.setItem($(this).attr('id'), 'shown');
    });
    //*savecollapsestate*//


});


//display/hide size menu based on media size
function ResizeMediaJS(x) {

    if (document.getElementById("sidebar-wrapper")) {//don't resize ee

        if (x.matches) { 
            $("#sidebar-wrapper").removeClass("in");
            $("#wrapper").removeClass("toggled");
            $("#sidebar-wrapper").removeClass("show");
            $("#menu-toggle").addClass("collapsed");
            $("#menu-toggle").attr("aria-expanded", false);
        } else {
            $("#sidebar-wrapper").addClass("in");
            $("#wrapper").addClass("toggled");
            $("#sidebar-wrapper").addClass("show");
            $("#menu-toggle").removeClass("collapsed");
            $("#menu-toggle").attr("aria-expanded", true);
        }

    }
}

let resizeMatch = window.matchMedia("(max-width: 767px)")
ResizeMediaJS(resizeMatch) 
resizeMatch.addListener(ResizeMediaJS) 



/** old utilities */
function SelectRadio(FormName, RadioName, ItemNumber) {
    document[FormName][RadioName][ItemNumber].checked = true;
}

function ToggleCheckbox(FormName, CheckboxName) {
    if (document[FormName][CheckboxName].checked) {
        document[FormName][CheckboxName].checked = false;
    }
    else {
        document[FormName][CheckboxName].checked = true;
    }
}

function checkAll(sel, field) {
    if (sel.checked)
        for (i = 0; i < field.length; i++)
            field[i].checked = true;
    if (sel.checked == false)
        for (i = 0; i < field.length; i++)
            field[i].checked = false;
}
/** end old utilities */