import { PopupHelp, PopupHelpEmployee, PopupHelpAgent, OpenPolicy, OpenEVerifyParticipationNotice, PopupUserGuide} from './popuphelp.esm.js';
//import "/css/fragomen.css";
import "./../../../../css/site/masternav.css";
import "./../../../../css/site/fragcustom.css";
import "./../../../../css/site/legacy.css";

window.PopupHelp = PopupHelp;
window.PopupHelpEmployee = PopupHelpEmployee;
window.PopupHelpAgent = PopupHelpAgent;
window.OpenPolicy = OpenPolicy ;
window.OpenEVerifyParticipationNotice = OpenEVerifyParticipationNotice;
window.PopupUserGuide = PopupUserGuide;